<template>
  <b-card
    no-body
    class="card-banner-ads h-100"
  >
    <div class="bg-light-primary rounded-top text-center">
      <swiper
        class="swiper-navigations"
        :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      >
        <swiper-slide
          v-for="(data,index) in swiperData"
          :key="index"
        >
          <b-img
            :src="data.img"
            fluid
          />
        </swiper-slide>
        <div
          slot="pagination"
          class="swiper-pagination"
        />
      </swiper>
    </div>
    <b-card-header class="pb-0 heading">
      <b-card-title>Internal Ad Campaigns</b-card-title>
    </b-card-header>
    <b-card-body :class="allBannerCampaigns.length===0 && bannerCampaignLoading=== false? 'noCampaign':null">
      <b-skeleton-wrapper
        :loading="bannerCampaignLoading"
        class="p-2"
      >
        <template #loading>
          <b-skeleton width="100%" />
          <b-skeleton width="75%" />
          <b-skeleton width="50%" />
        </template>
        <div
          v-if="allBannerCampaigns.length === 0"
        >
          No Internal Network Ad found
        </div>
        <div
          v-for="(item, index) in 3"
          v-else
          :key="index"
          class="d-flex my-2 mb-0"
        >
          <b-row
            v-if="allBannerCampaigns[index]"
            class="w-100 ml-0"
          >
            <b-col class="w-90 pl-0">
              <h6 class="mb-0 text-primary pl-0 ">
                {{ allBannerCampaigns[index].name }}
              </h6>
            </b-col>
            <!-- <b-col class="w-10 pr-0">
              <div
                style="float: right"
                class="pr-0"
              >
                <vs-switch
                  disabled
                  circle
                  :value="allBannerCampaigns[index].is_active"
                  class="switchs"
                  :style="`background-color: ${allBannerCampaigns[index].is_active ? '#00E265' : '#FF0000'};color:white;`"
                  style="float: right"
                >
                  <span
                    slot="on"
                    style="font-weight: bold"
                  >Disable</span>
                  <span
                    slot="off"
                    style="font-weight: bold"
                  >Enable</span>
                </vs-switch>
              </div>
            </b-col> -->
          </b-row>
          <b-row
            v-else
            class="w-100 ml-0 campaignDiv"
          >
            <b-col class="w-90 pl-0">
              <h6 class="mb-0 text-primary pl-0 " />
            </b-col>
            <b-col class="w-10 pr-0" />
          </b-row>
        </div>
      </b-skeleton-wrapper>
    </b-card-body>
    <b-button
      variant="link"
      class="links"
      @click="exploreCampaignPage"
    >
      Open
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle, VBTooltip, BImg, BCardHeader, BButton, BRow, BCol, BSkeletonWrapper, BSkeleton,
} from 'bootstrap-vue'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'

Vue.use(Vuesax)
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BButton,
    Swiper,
    SwiperSlide,
    BRow,
    BCol,
    BSkeletonWrapper,
    BSkeleton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      bannerCampaignLoading: false,
      allBannerCampaigns: [],
      campaign: '',
      swiperData: [
        { img: require('@/assets/images/simiicons/banerAds.png') },
        { img: require('@/assets/images/simiicons/banerAds.png') },
        { img: require('@/assets/images/simiicons/banerAds.png') },
        { img: require('@/assets/images/simiicons/banerAds.png') },
        { img: require('@/assets/images/simiicons/banerAds.png') },
      ],
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
        },
      },
    }
  },
  computed: {
    getPermissionOfMember() {
      return this.$store.getters['user/getPermissionOfMember'](MemberPermissions.INTERNAL_NETWORK_ADS)
    },
  },
  mounted() {
    this.getAllBannerCampaigns()
  },
  methods: {
    getAllBannerCampaigns() {
      if (this.getPermissionOfMember) {
        this.bannerCampaignLoading = true
        this.$axios
          .get('banner-campaigns')
          .then(({ data: { data } }) => {
            this.allBannerCampaigns = data
            this.bannerCampaignLoading = false
          })
          .catch(error => ({ error }))
      }
    },
    exploreCampaignPage() {
      this.$router.push('/banner-ads')
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.card-banner-ads{
  margin-bottom: 0;
}
.switch {
  width: 50px;
  border-radius: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #00E265;
  color: white;
}
.sliderImage{
  height: 170px !important;
}
.links{
    text-decoration: underline !important;
    margin-top: 8px !important;
    padding-top: 0px !important;
}
.heading{
  padding-top: 10px !important;
}
.switchs {
  width: 50px;
  padding-inline: 40px;
  border-radius: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
  // background-color: #7FF0B2;
  color: white;
}
.swiperImage{
  width: 100px;
  height: 100px;
}
.campaignDiv{
  min-height: 28px;
}
.noCampaign{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
