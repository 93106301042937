<template>
  <b-card
    no-body
    class="card-employee-task h-100"
  >
    <b-card-header>
      <b-card-title>Media Storage</b-card-title>
    </b-card-header>
    <!-- body -->
    <b-card-body :class="allMediaStorageData.length === 0 && loading === false? 'noMedia': null">
      <b-skeleton-table
        v-if="loading"
        :rows="4"
        :columns="3"
        :table-props="{ striped: true, tableClass:'skeleton-loading-table' }"
      />
      <div
        v-if="allMediaStorageData.length === 0"
      >
        No Media Storage found
      </div>
      <div
        v-for="(media) in allMediaStorageData"
        v-else
        :key="media.id"
        class="employee-task d-flex justify-content-between align-items-center"
      >
        <b-media no-body>
          <b-media-aside class="mr-75">
            <b-avatar class="avatar">
              <b-img
                :src="getThumbnailOfFile(media)"
                class="userMediaImage"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <b-link>
              <h6 class="mb-0 text-primary">
                {{ media.name }}
              </h6>
            </b-link>
            <small>{{ media.type }}</small>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <small class="text-muted mr-75">{{ media.duration }} Sec</small>
        </div>
      </div>
    </b-card-body>
    <b-button
      variant="link"
      class="links"
      @click="exploreMediaStorage"
    >
      Open
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody, BMedia, BMediaAside, BMediaBody, BLink, BImg, BButton, BAvatar, BSkeletonTable,
} from 'bootstrap-vue'
import { getThumbnailOfFile } from '@/common/global/functions'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BButton,
    BAvatar,
    BSkeletonTable,
    // VueApexCharts,
  },
  data() {
    return {
      loading: false,
      allMediaStorageData: [],
    }
  },
  computed: {
    getSelectedGroupMemberPermissions() {
      return this.$store.getters['user/getSelectedGroupMemberPermissions']
    },
  },
  async mounted() {
    this.canViewIfHasPermission()
    await this.getAllMediaStorageOfUser()
  },
  methods: {
    canViewIfHasPermission() {
      const permissionArray = this.getSelectedGroupMemberPermissions
      if (permissionArray.includes('media')) {
        return true
      }
      return false
    },
    getThumbnailOfFile(file) {
      return getThumbnailOfFile(file)
    },
    getAllMediaStorageOfUser() {
      this.loading = true
      if (!this.canViewIfHasPermission()) {
        this.allMediaStorageData = []
      } else {
        this.$axios
          .get(`media?offset=${0}&limit=${5}`)
          .then(({ data: { data } }) => {
            this.allMediaStorageData = data.results
            this.loading = false
          })
          .catch(error => ({ error }))
      }
      this.loading = false
    },
    exploreMediaStorage() {
      this.$router.push('/media-storage')
    },
  },
}
/* eslint-disable global-require */
</script>
<style scoped>
.links{
    text-decoration: underline !important;
}
.card-employee-task{
margin-bottom: 0;
}
.userMediaImage{
  width: 43px;
  height: 43px;
}
.avatar{
  background-color: white;
  border-color: #b8c2cc;
  border-style: solid;
  border-width: thin;
  width: 45px;
  height: 45px;
}
.noMedia{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

</style>
